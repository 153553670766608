import { Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { landingRoutes } from './components/landing/landing.routes';
import { AuthGuard } from './shared/services/auth/auth.guard';
import { LoadingComponent } from './shared/components/ui-components/loading/loading.component';

const childRoutes = [
  {
    path: '',
    children: landingRoutes,
  },
  {
    path: 'channels',
    loadChildren: () =>
      import('./components/channel/channels.routes').then(m => m.channelRoutes),
  },
  {
    path: 'missing-groups',
    loadChildren: () =>
      import('./components/missing-group/missing-group.routes').then(
        c => c.missingGroupRoutes
      ),
  },
  {
    path: 'imports',
    loadChildren: () =>
      import('./components/file/import.routes').then(m => m.importRoutes),
  },
  {
    path: 'exports',
    loadChildren: () =>
      import('./components/exports/exports.routes').then(m => m.exportRoutes),
  },
  {
    path: 'webhooks',
    loadChildren: () =>
      import('./components/webhook/webhook.routes').then(m => m.webhookRoutes),
  },
];

export const routes: Routes = [
  {
    path: 'sso',
    loadComponent: () =>
      import('./components/sso/sso.component').then(c => c.SsoComponent),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadComponent: environment.isDemo
      ? () =>
          import(
            './shared/components/theme/theme-demo-border/theme-demo-border.component'
          ).then(c => c.ThemeDemoBorderComponent)
      : () =>
          import(
            './shared/components/theme/theme-dev/theme-dev.component'
          ).then(c => c.ThemeDevComponent),
    children: [
      {
        path: '',
        children: childRoutes,
      },
      {
        path: ':userId',
        children: childRoutes,
      },
    ],
  },
];
