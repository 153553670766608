import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ImporterMissingGroupActions } from './import-missing-group.actions';
import {
  DocumentData,
  Firestore,
  Query,
  addDoc,
  collection,
  deleteDoc,
  doc,
  increment,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  where,
} from '@angular/fire/firestore';
import { ImporterMissingGroup, typeToJSON } from 'src/app/shared/models';
import { Action } from '@ngrx/store';

@Injectable()
export class ImporterMissingGroupEffects {
  loadImporterMissingGroups$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImporterMissingGroupActions.loadImporterMissingGroups),
      mergeMap(({ userId, from, size, term, importerMissingGroupType }) => {
        return new Observable<Action>(subscriber => {
          console.log('loadImporterMissingGroups', {
            userId,
            from,
            size,
            term,
            importerMissingGroupType,
          });

          let q: Query<DocumentData, DocumentData> = collection(
            this.firestore,
            'users',
            userId,
            'importerMissingGroups'
          );

          // if (from) {
          //   q = query(q, startAfter(from));
          // }

          // if (size) {
          //   q = query(q, limit(size));
          // }

          // // if (importerSource) {
          // //   q = query(
          // //     q,
          // //     where(
          // //       'source',
          // //       '==',
          // //       importerMissingGroup_SourceToJSON(importerSource)
          // //     )
          // //   );
          // // }
          // if (importerMissingGroupType) {
          //   q = query(
          //     q,
          //     where('type', '==', typeToJSON(importerMissingGroupType))
          //   );
          // }

          // if (term) {
          //   q = query(
          //     q,
          //     where('name', '>=', term),
          //     where('name', '<=', term + '\uf8ff')
          //   );
          // }

          // q = query(q, orderBy('name'));

          const unsubscribe = onSnapshot(
            q,
            snapshot => {
              console.log('loadImporterMissingGroups snapshot', snapshot.size);

              const importerMissingGroups = snapshot.docs.map(doc =>
                ImporterMissingGroup.fromJSON({
                  ...doc.data(),
                  id: doc.id,
                  user: { id: userId },
                })
              );
              console.log(
                'loadImporterMissingGroups success',
                importerMissingGroups
              );
              subscriber.next(
                ImporterMissingGroupActions.loadImporterMissingGroupsSuccess({
                  importerMissingGroups,
                })
              );
            },
            error => {
              console.log('loadImporterMissingGroups error', error);
              subscriber.next(
                ImporterMissingGroupActions.loadImporterMissingGroupsFailure({
                  error,
                })
              );
            }
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError(error =>
            of({
              type: '[ImporterMissingGroup API] Load ImporterMissingGroups Error',
              error,
            })
          )
        );
      })
    );
  });

  loadImporterMissingGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImporterMissingGroupActions.loadImporterMissingGroup),
      mergeMap(({ importerMissingGroupId, userId }) => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            doc(
              this.firestore,
              'users',
              userId,
              'importerMissingGroups',
              importerMissingGroupId
            ),
            snapshot => {
              const importerMissingGroup = ImporterMissingGroup.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
                user: { id: userId },
              });
              subscriber.next(
                ImporterMissingGroupActions.loadImporterMissingGroupSuccess({
                  importerMissingGroup,
                })
              );
            },
            error => {
              subscriber.next(
                ImporterMissingGroupActions.loadImporterMissingGroupFailure({
                  error,
                })
              );
            }
          );
          return unsubscribe;
        });
      })
    );
  });

  addImporterMissingGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImporterMissingGroupActions.addImporterMissingGroup),
      mergeMap(async ({ userId, importerMissingGroup }) => {
        try {
          const docRef = await addDoc(
            collection(
              this.firestore,
              'users',
              userId,
              'importerMissingGroups'
            ),
            ImporterMissingGroup.toJSON(importerMissingGroup)
          );
          console.log('doc added', docRef);
          return ImporterMissingGroupActions.addImporterMissingGroupSuccess({
            importerMissingGroup: ImporterMissingGroup.fromJSON({
              ...importerMissingGroup,
              id: docRef.id,
              user: { id: userId },
            }),
          }); // return new importerMissingGroup with id
        } catch (error) {
          return ImporterMissingGroupActions.addImporterMissingGroupFailure({
            error,
          });
        }
      })
    );
  });

  removeImporterMissingGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImporterMissingGroupActions.removeImporterMissingGroup),
      mergeMap(async ({ userId, importerMissingGroupId }) => {
        try {
          await deleteDoc(
            doc(
              this.firestore,
              'users',
              userId,
              'importerMissingGroups',
              importerMissingGroupId
            )
          );
          return ImporterMissingGroupActions.removeImporterMissingGroupSuccess({
            importerMissingGroupId,
          }); // return removed importerMissingGroup's id
        } catch (error) {
          return ImporterMissingGroupActions.removeImporterMissingGroupFailure({
            error,
          });
        }
      })
    );
  });

  updateImporterMissingGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImporterMissingGroupActions.updateImporterMissingGroup),
      mergeMap(
        async ({ userId, importerMissingGroupId, importerMissingGroup }) => {
          try {
            await setDoc(
              doc(
                this.firestore,
                'users',
                userId,
                'importerMissingGroups',
                importerMissingGroupId
              ),
              {
                ...(ImporterMissingGroup.toJSON(importerMissingGroup) as any),
                currentVersion: increment(1),
              },
              {
                merge: true,
              }
            );
            return ImporterMissingGroupActions.updateImporterMissingGroupSuccess(
              {
                userId,
                importerMissingGroupId,
                importerMissingGroup,
              }
            ); // return updated importerMissingGroup's id and changes
          } catch (error) {
            console.error('updateImporterMissingGroup error', error);
            return ImporterMissingGroupActions.updateImporterMissingGroupFailure(
              { error }
            );
          }
        }
      )
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore
  ) {}
}
