/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  EnvironmentInjector,
  inject,
  Injectable,
  runInInjectionContext,
} from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AgentActions } from './agent.actions';
import {
  Firestore,
  Unsubscribe,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
} from '@angular/fire/firestore';
import { Agent, UserType } from 'src/app/shared/models';
import { Action } from '@ngrx/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable()
export class AgentEffects {
  private environmentInjector = inject(EnvironmentInjector);

  loadAgents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgentActions.loadAgents),
      mergeMap(() => {
        return new Observable<Action>(subscriber => {
          let unsubscribe: Unsubscribe | undefined;
          runInInjectionContext(this.environmentInjector, () => {
            unsubscribe = onSnapshot(
              collection(this.firestore, 'agents'),
              snapshot => {
                const agents = snapshot.docs.map(doc =>
                  Agent.fromJSON({
                    ...doc.data(),
                    id: doc.id,
                    userType: UserType.AGENT,
                  })
                );
                subscriber.next(AgentActions.loadAgentsSuccess({ agents }));
              },
              error => {
                subscriber.next(AgentActions.loadAgentsFailure({ error }));
              }
            );
          });

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError(error =>
            of({ type: '[Agent API] Load Agents Error', error })
          )
        );
      })
    );
  });

  loadAgent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgentActions.loadAgent),
      mergeMap(({ agentId }) => {
        return new Observable<Action>(subscriber => {
          console.log('loading agent', agentId);
          let unsubscribe: Unsubscribe | undefined;
          runInInjectionContext(this.environmentInjector, () => {
            unsubscribe = onSnapshot(
              doc(this.firestore, 'agents', agentId),
              snapshot => {
                const agent = Agent.fromJSON({
                  ...snapshot.data(),
                  id: snapshot.id,
                  userType: UserType.AGENT,
                });
                console.log('got agent', agent);
                subscriber.next(AgentActions.loadAgentSuccess({ agent }));
              },
              error => {
                subscriber.next(AgentActions.loadAgentFailure({ error }));
              }
            );
          });
          return unsubscribe;
        });
      })
    );
  });

  addAgent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgentActions.addAgent),
      mergeMap(async ({ agent }) => {
        try {
          const docRef = await addDoc(
            collection(this.firestore, 'agents'),
            Agent.toJSON(agent) as any
          );
          return AgentActions.addAgentSuccess({
            agent: { ...agent, id: docRef.id },
          }); // return new agent with id
        } catch (error) {
          return AgentActions.addAgentFailure({ error: error as Error });
        }
      })
    );
  });

  removeAgent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgentActions.removeAgent),
      mergeMap(async ({ agentId }) => {
        try {
          await deleteDoc(doc(this.firestore, 'agents', agentId));
          return AgentActions.removeAgentSuccess({ agentId }); // return removed agent's id
        } catch (error) {
          return AgentActions.removeAgentFailure({ error: error as Error });
        }
      })
    );
  });

  updateAgent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgentActions.updateAgent),
      mergeMap(async ({ agentId, agent, agencyId }) => {
        console.log('updating agent', agentId, agent);

        const parsed = Agent.toJSON(agent);

        const result = await httpsCallable(
          this.functions,
          'agentUpdate'
        )({ agencyId, agentId, agent: parsed });
        console.log('agentUpdate update', result);

        const data = result.data as { agent: unknown };
        const resultAgent = data?.agent ? Agent.fromJSON(data.agent) : null;

        if (!resultAgent) {
          return AgentActions.updateAgentFailure({
            error: new Error('Agent not found'),
          });
        }

        return AgentActions.updateAgentSuccess({
          agentId,
          agent,
        }); // return new agent with id
      })
    );
  });

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private firestore: Firestore,
    private functions: Functions
  ) {}
}
