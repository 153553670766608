import {
  ApplicationConfig,
  ErrorHandler,
  PLATFORM_ID,
  importProvidersFrom,
  inject,
  isDevMode,
  provideAppInitializer,
} from '@angular/core';
import { Router, provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import {
  getAnalytics,
  provideAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { initializeFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { isPlatformServer } from '@angular/common';
import { TraceService, createErrorHandler } from '@sentry/angular';
import {
  initializeAppCheck,
  provideAppCheck,
  ReCaptchaEnterpriseProvider,
} from '@angular/fire/app-check';
import { AuthGuard } from './shared/services/auth/auth.guard';
import * as fromAgent from './shared/store/agent/+state/agent.reducer';
import * as fromAgency from './shared/store/agency/+state/agency.reducer';
import * as fromAuthority from './shared/store/authority/+state/authority.reducer';
import * as fromCarrier from './shared/store/carrier/+state/carrier.reducer';
import * as fromCommissionCycle from './shared/store/commission-cycle/+state/commission-cycle.reducer';
import * as fromConsumer from './shared/store/consumer/+state/consumer.reducer';
import * as fromEmployee from './shared/store/employee/+state/employee.reducer';
import * as fromImporterChannel from './shared/store/import-channel/+state/import-channel.reducer';
import * as fromImporterImport from './shared/store/importer-import/+state/importer-import.reducer';
import * as fromImporterMissingGroup from './shared/store/import-missing-group/+state/import-missing-group.reducer';
import * as fromWebhookChannel from './shared/store/webhook-channel/+state/webhook-channel.reducer';
import * as fromPermissionGranted from './shared/store/permission-granted/+state/permission-granted.reducer';
import { AgentEffects } from './shared/store/agent/+state/agent.effects';
import { AgencyEffects } from './shared/store/agency/+state/agency.effects';
import { AuthorityEffects } from './shared/store/authority/+state/authority.effects';
import { CarrierEffects } from './shared/store/carrier/+state/carrier.effects';
import { CommissionCycleEffects } from './shared/store/commission-cycle/+state/commission-cycle.effects';
import { ConsumerEffects } from './shared/store/consumer/+state/consumer.effects';
import { EmployeeEffects } from './shared/store/employee/+state/employee.effects';
import { ImporterChannelEffects } from './shared/store/import-channel/+state/import-channel.effects';
import { ImporterMissingGroupEffects } from './shared/store/import-missing-group/+state/import-missing-group.effects';
import { PermissionGrantedEffects } from './shared/store/permission-granted/+state/permission-granted.effects';
import { ImporterImportEffects } from './shared/store/importer-import/+state/importer-import.effects';
import { provideHttpClient } from '@angular/common/http';
import { provideServiceWorker } from '@angular/service-worker';
import { provideAnimations } from '@angular/platform-browser/animations';
import { WebhookChannelEffects } from './shared/store/webhook-channel/+state/webhook-channel.effects';
import { LeadListEffects } from './shared/store/lead-list/+state/lead-list.effects';
import * as fromLeadList from './shared/store/lead-list/+state/lead-list.reducer';

const provideSentryErrorHandler = (opts = {}) => {
  return {
    provide: ErrorHandler,
    useValue: createErrorHandler(opts),
  };
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(),
    provideAnimations(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    provideFirestore(() =>
      initializeFirestore(getApp(), { ignoreUndefinedProperties: true })
    ),
    provideFunctions(() => getFunctions(getApp(), 'us-east1')),
    providePerformance(() => getPerformance()),
    provideStorage(() => getStorage()),
    //@ts-expect-error
    provideAppCheck(() => {
      const platformId = inject(PLATFORM_ID);
      if (isPlatformServer(platformId)) {
        return;
      }
      const provider = new ReCaptchaEnterpriseProvider(
        environment.firebase.appCheckRecaptchaSiteKey
      );
      return initializeAppCheck(undefined, {
        provider,
        isTokenAutoRefreshEnabled: true,
      });
    }),
    importProvidersFrom([ScreenTrackingService, UserTrackingService]),
    provideStore({
      [fromAgent.agentFeatureKey]: fromAgent.reducer,
      [fromAgency.agencyFeatureKey]: fromAgency.reducer,
      [fromAuthority.authorityFeatureKey]: fromAuthority.reducer,
      [fromCarrier.carrierFeatureKey]: fromCarrier.reducer,
      [fromCommissionCycle.commissionCycleFeatureKey]:
        fromCommissionCycle.reducer,
      [fromConsumer.consumerFeatureKey]: fromConsumer.reducer,
      [fromEmployee.employeeFeatureKey]: fromEmployee.reducer,
      [fromImporterChannel.importerChannelFeatureKey]:
        fromImporterChannel.reducer,
      [fromImporterImport.importerImportFeatureKey]: fromImporterImport.reducer,
      [fromLeadList.leadListFeatureKey]: fromLeadList.reducer,
      [fromImporterMissingGroup.importerMissingGroupFeatureKey]:
        fromImporterMissingGroup.reducer,
      [fromPermissionGranted.permissionGrantedFeatureKey]:
        fromPermissionGranted.reducer,
      [fromWebhookChannel.webhookChannelFeatureKey]: fromWebhookChannel.reducer,
    }),
    provideEffects([
      AgentEffects,
      AgencyEffects,
      AuthorityEffects,
      CarrierEffects,
      CommissionCycleEffects,
      ConsumerEffects,
      EmployeeEffects,
      ImporterChannelEffects,
      ImporterImportEffects,
      LeadListEffects,
      ImporterMissingGroupEffects,
      PermissionGrantedEffects,
      WebhookChannelEffects,
    ]),
    provideStoreDevtools({
      connectInZone: true,
    }),
    provideSentryErrorHandler({
      showDialog: false,
    }),
    {
      provide: TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      inject(TraceService);
    }),
    provideHttpClient(),
    AuthGuard,
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};
